<template>
  <div v-if="!isMobile" class="container-pc">
    <div style="background-color: #193db3; padding-top: 0.1px">
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
          <h1 class="logo">
            <a><img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/03/25/575f2b4917254290be12e5a5be3c5be1/logo.png" alt="" /></a>
          </h1>
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto" :class="{'active':$route.name === 'home'}" style="cursor: pointer" @click="$router.push({name: 'home'})">{{ isCN ? "首页" : "Home" }}</a></li>
              <!--<li class="dropdown">
                <a>
                  {{ isCN ? "日程" : "Program" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <template v-if="isCN">
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864" target="_blank">会议日程</a></li>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864" target="_blank">讲者检索</a></li>
                  </template>
                  <template v-else>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864?en=1" target="_blank">Forums</a></li>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864&en=1" target="_blank">Speakers</a></li>
                  </template>
                </ul>
              </li>-->
              <li v-if="isCN">
                <a
                    class="nav-link scrollto"
                    href="https://www.drvoice.cn/v2/special/672"
                >Workshop预约</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    target="_blank"
                    :href="isCN?'https://www.drvoice.cn/v2/programme/1417084311088128':'https://www.drvoice.cn/v2/programme/1417084311088128?en=1'"
                    style="cursor: pointer"
                >{{ isCN ? "日程" : "Program" }}
              </a>
              </li>
              <li>
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/course/3328"
                  target="_blank"
                >{{ isCN ? "网络直播" : "Webcast" }}</a>
              </li>
              <li>
                <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
                  target="_blank"
                >Webinars</a>
              </li>
              <li v-if="isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.pailixiang.com/m/album/main_ig65930788.html"
                  target="_blank"
                >照片直播</a>
              </li>
              <li class="dropdown" v-if="isCN">
                <a class="nav-link">
                  会议资料
                  <i class="bi bi-chevron-down"></i>
                </a>
                <ul class="huigu">
                  <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1425222301057536" target="_blank">会议报道</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1423367011369472" target="_blank">资料下载</a></li>
                </ul>
              </li>
              <li v-else>
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1504/column/1423366932603392?language=en"
                  target="_blank"
                >Download</a>
              </li>
              <li class="dropdown">
                <a class="nav-link">
                  {{ isCN ? "投稿" : "Submission" }}
                  <i class="bi bi-chevron-down"></i>
                </a>
                <ul class="huigu">
                  <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1428197029241088" target="_blank">{{ isCN ? "征文投稿结果" : "Call For Abstracts Result" }}</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1428200868643328" target="_blank">{{ isCN ? "病例投稿结果" : "Call For Cases Result" }}</a></li>
                  <li><a :class="{'active':$route.name === 'essaySubmission'}" @click="$router.push({name: 'essaySubmission'})" target="_blank">{{ isCN ? "征文投稿" : "Call For Abstracts" }}</a></li>
                  <li><a :class="{'active':$route.name === 'caseSubmission'}" @click="$router.push({name: 'caseSubmission'})" target="_blank">{{ isCN ? "病例投稿" : "Call For Cases" }}</a></li>
                </ul>
              </li>
              <!--<li v-if="!isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1400736462077952?language=en"
                >Download</a>
              </li>
              <li class="dropdown" v-if="isCN">
                <a>
                  会议资料
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1403064991039744">参会指南</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240">会议资讯</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1400736361062400">资料下载</a></li>
                </ul>
              </li>
              <li v-if="!isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240"
                >News</a>
              </li>
              <li >
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/special/553"
                >{{isCN? "病例大赛" : "Case"}}</a>
              </li>
              <li v-if="isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/sroom/1120"
                >云展厅</a>
              </li>-->
              <li class="dropdown">
                <a class="nav-link">
                  {{ isCN ? "回顾" : "Review" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a @click="goPrevious(2024)">2024</a></li>
                  <li><a @click="goPrevious(2023)">2023</a></li>
                  <li><a @click="goPrevious(2022)">2022</a></li>
                  <li><a @click="goPrevious(2021)">2021</a></li>
                  <li><a @click="goPrevious(2020)">2020</a></li>
                  <li><a @click="goPrevious(2019)">2019</a></li>
                  <li><a @click="goPrevious(2018)">2018</a></li>
                  <li><a @click="goPrevious(2017)">2017</a></li>
                  <li><a @click="goPrevious(2016)">2016</a></li>
                </ul>
              </li>
              <li>
                <a href="https://mm.sciconf.cn/cn/user/login/28866" v-if="isCN">登录注册</a>
                <a :class="{'active':$route.name === 'sign'}" href="https://mm.sciconf.cn/en/user/login/28866" v-else>Sign up</a>
              </li>
            </ul>
            <div class="lang-check" @click="toggleLanguage">
              {{ isCN ? "EN | 英文" : "CN | 中文" }}
            </div>
            <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"/>
          </nav>
        </div>
      </header>
    </div>
    <router-view :key="key"/>
  </div>
  <!-- <div v-else class="container-mb">
    <header id="header" class="d-flex align-items-center">
      <div class="container d-flex align-items-center justify-content-between">
        <div></div>
        <nav id="navbar" class="navbar">
          <div class="lang-check" @click="toggleLanguage">
            {{ isCN ? "EN | 英文" : "CN | 中文" }}
          </div>
          <ul>
            <li>
              <a
                @click="$router.push({name: 'home'})"
                class="nav-link scrollto "
                :class="{'active':$route.name === 'home'}"
                style="cursor: pointer"
                >{{ isCN ? "首页" : "Home" }}</a
              >
            </li>
            <li>
              <a
                class="nav-link scrollto"
                :class="{'active':$route.name === 'essaySubmission'}"
                @click="$router.push({name: 'essaySubmission'})"
                style="cursor: pointer"
              >{{ isCN ? "征文投稿" : "Call For Abstracts" }}</a>
            </li>
            <li>
              <a
                class="nav-link scrollto"
                :class="{'active':$route.name === 'caseSubmission'}"
                @click="$router.push({name: 'caseSubmission'})"
                style="cursor: pointer"
              >{{ isCN ? "病例投稿" : "Call For Cases" }}</a>
            </li>
            <li>
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/course/3328"
              >{{ isCN ? "网络直播" : "Webcast" }}</a>
            </li>
            <li>
              <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
              >Webinars</a>
            </li>
            <li v-if="isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.pailixiang.com/m/album/main_ig65930788.html"
              >照片直播</a>
            </li>
            <li class="dropdown" @click="xiazaidropdownHandle" v-if="isCN">
              <a>
                会议资料
                <i class="bi bi-chevron-down"></i>
              </a>
              <ul class="xiazai">
                <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1425222301057536" target="_blank">会议报道</a></li>
                <li><a href="https://www.drvoice.cn/v2/conf/1504/column/1423367011369472" target="_blank">资料下载</a></li>
              </ul>
            </li>
            <li v-else>
              <a
                class="nav-link scrollto"
                href="https://www.drvoice.cn/v2/conf/1504/column/1423366932603392?language=en"
                target="_blank"
              >Download</a>
            </li>
            <li class="dropdown" @click="dropdownHandle">
              <a>
                {{ isCN ? "回顾" : "Review" }}
                <i class="bi bi-chevron-down"/>
              </a>
              <ul class="huigu">
                <li><a @click="goPrevious(2024)">2024</a></li>
                <li><a @click="goPrevious(2023)">2023</a></li>
                <li><a @click="goPrevious(2022)">2022</a></li>
                <li><a @click="goPrevious(2021)">2021</a></li>
                <li><a @click="goPrevious(2020)">2020</a></li>
                <li><a @click="goPrevious(2019)">2019</a></li>
                <li><a @click="goPrevious(2018)">2018</a></li>
                <li><a @click="goPrevious(2017)">2017</a></li>
                <li><a @click="goPrevious(2016)">2016</a></li>
              </ul>
            </li>
            <li>
              <a href="https://www.drvoice.cn/v2/conf/1504/home" v-if="isCN">登录注册</a>
              <a :class="{'active':$route.name === 'sign'}" @click="$router.push({name: 'sign'})" v-else>Sign up</a>
            </li>
          </ul>
          <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
    <div style="margin-top: 50px;">
      <router-view :key="key"/>
    </div>
  </div> -->
  <div v-else class="CVH-mb">
    <img
      src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/43e81e1815054effb2bcad1150ea5869.jpg"
    />
    <div>
      <div class="wap-grid">
        <div class="wap-grid-6">
          <a href="https://www.drvoice.cn/v2/course/3328" target="_blank">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/b319bac55b2f40e4b00e74fc65602b28.jpg"
              width="100%"
            />
          </a>
        </div>
        <div class="wap-grid-6">
          <a :href="isCN?'https://www.drvoice.cn/v2/conf/1504/column/1426709722213376':'https://www.drvoice.cn/v2/conf/1504/column/1426709722213377?language=en'" target="_blank">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/06614a65188b431e8e6fd3f2235aea6c.jpg"
              width="100%"
            />
          </a>
        </div>
      </div>

      <div class="wap-grid">
        <!-- 照片直播 -->
        <div class="wap-grid-6">
          <a @click="wait">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/de94d2c2a8754745839b2de6f1a93c6e.jpg"
              width="100%"
            />
          </a>
        </div>
        <!-- 日程 -->
        <div class="wap-grid-6">
          <a :href="isCN?'https://www.drvoice.cn/v2/programme/1417084311088128':'https://www.drvoice.cn/v2/programme/1417084311088128?en=1'" target="_blank">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/3aa7f9a1e01c4ffe8127910039faaf6d.jpg"
              width="100%"
            />
          </a>
        </div>
      </div>

      <div class="wap-grid" style="fontSize:0">
        <div class="wap-grid-4">
          <a href="https://www.drvoice.cn/v2/special/672" target="_blank">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/5a099161977e4862afbbadd73640a8f4.jpg"
              width="100%"
            />
          </a>
        </div>
        <div class="wap-grid-4">
          <a @click="wait">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/d6ab853257a44e7ebbcb27786d27cd8e.jpg"
              width="100%"
            />
          </a>
        </div>
        <div class="wap-grid-4">
          <a
            href="https://www.drvoice.cn/v2/conf/1504/column/1425222301057536"
            target="_blank"
          >
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/f1cc9528409e4904bd11d71b7f4c4f08.jpg"
              width="100%"
            />
          </a>
        </div>
      </div>
      <div class="wap-grid" style="fontSize:0">
        <div class="wap-grid-4">
          <a @click="wait">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/212eb14523bc40dbb0eaaf1783f138ff.jpg"
              width="100%"
            />
          </a>
        </div>
        <div class="wap-grid-4">
          <a
            :href="isCN?'https://www.drvoice.cn/v2/conf/1504/column/1423367011369472':'https://www.drvoice.cn/v2/conf/1504/column/1423366932603392?language=en'"
            target="_blank"
          >
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/564fb61064ee4d9897c1bf6efb4608c6.jpg"
              width="100%"
            />
          </a>
        </div>
        <div class="wap-grid-4">
          <a href="https://www.drvoice.cn/v2/conf/1504/column/1426709338696704" target="_blank">
            <img
              src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/c6ebb6feb8284cbeb45add263cc6f81a.jpg"
              width="100%"
            />
          </a>
        </div>
      </div>
      <a :href="isCN?'https://mm.sciconf.cn/cn/user/login/28866':'https://mm.sciconf.cn/en/user/login/28866'" target="_blank">
        <img
          src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/3b77536e4e624eaf85971189355b4f91.jpg"
        />
      </a>
      <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2025/03/10/c7a62368e783452bb679d96bf1865977.jpg" alt="">
    </div>
  </div>
</template>

<script>
import { selectEl } from "../utils/util";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/bootstrap-utilities.css";
import {initWeChatForwardConfiguration} from "@/utils/weChatForward";
import { mutations } from '../utils/global'

export default {
  name: "Home",
  data() {
    return {
      isMobile: "",
      isfold: true,
      isAllText: true,
      headerFixed: null
    };
  },
  created() {
    initWeChatForwardConfiguration("CHINA VALVE（HANGZHOU）2025", window.location.href, "", "2025年4月14-20日 中国·杭州")
    const mobileAgent = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    const mobileTouch = "ontouchstart" in document.documentElement;
    const mobileMedia = window.matchMedia(
      "only screen and (max-width: 767px)"
    ).matches;
    this.isMobile = !!(mobileAgent || mobileTouch || mobileMedia);
  },
  mounted() {
    let language = navigator.language === "zh-CN"
    language = !(this.$route.query.language === "en")
    mutations.setCNStatus(language)
    this.initAos();
    this.initHeader()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.headerFixed)
  },
  methods: {
    initHeader() {
      const selectHeader = document.querySelector('#header')
      if (!selectHeader) return
      this.headerFixed = () => {
        if (this.isMobile) {
          selectHeader.classList.add("fixed-top")
        } else {
          const shouldFix = window.scrollY >= 16
          selectHeader.classList.toggle("fixed-top", shouldFix)
        }
      }
      window.addEventListener('scroll', this.headerFixed)
      this.headerFixed()
    },
    initAos() {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    },
    mobileNavToggle() {
      selectEl("#navbar").classList.toggle("navbar-mobile");
      selectEl(".mobile-nav-toggle").classList.toggle("bi-list");
      selectEl(".mobile-nav-toggle").classList.toggle("bi-x");
    },
    dropdownHandle() {
      selectEl(".huigu").classList.toggle("dropdown-active");
    },
    dropdownHandles() {
      selectEl(".presenter").classList.toggle("dropdown-active");
    },
    forumsDropdownHandle(){
      selectEl(".forums").classList.toggle("dropdown-active");
    },
    xiazaidropdownHandle(){
      selectEl(".xiazai").classList.toggle("dropdown-active");
    },
    goPrevious(year) {
      let url = "http://cvh" + year + ".china-valve.org.cn/";
      if (!this.isCN) {
        url += "?language=en";
      }
      window.open(url);
    },
    toggleLanguage() {
      mutations.setCNStatus(!this.global.isCN)
    },
    wait(){
      this.$message.info("敬请期待/Stay Tuned")
    }
  },
  computed:{
    isCN() {
      return this.global.isCN
    },
    key() {
      return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
    }
  }
};
</script>

<style scoped>
.container-mb .scrolled-offset {
  margin-top: 50px;
}
.container-mb #header {
  height: 50px;
  /* background-color: #1015a3; */
  background-image: linear-gradient(to right, #0545b5, #000c60);
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.container-mb #header .navbar .mobile-nav-toggle {
  color: #fff;
  /* color: #193db3; */
}
.container-mb .lang-check {
  color: #193db3;
  background-color: #fff;
  /* border: 1px solid #193db3; */
  border-radius: 4px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  width: 78px;
  margin-right: 20px;
  margin-left: 80px;
  font-weight: 700;
}

.container-mb .banner h1 {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  color: #222222;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  background: linear-gradient(
    0deg,
    #ffebb9 0%,
    #ffd867 65.9912109375%,
    #ffeaa7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container-mb .banner .yy {
  width: 100%;
  position: absolute;
  top: 36px;
  left: 0;
  color: transparent;
  font-size: 26px;
  font-weight: 700;
  text-shadow: -3px 5px 5px rgba(0, 0, 0, 0.3), -2px -3px 0 #000;
}

.container-mb .banner h2 {
  color: #555555;
  margin: 20px 0 5px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.container-mb .banner .logo img {
  width: 100px;
}
.container-mb #hero .box {
  /* border-top: 1px solid #2c48c8;
    border-bottom: 1px solid #2c48c8; */
}
.container-mb #hero .img-list {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.container-mb #hero .img-list .img-box {
  border: 1px solid #2c48c8;
  margin: 0 5px;
  list-style: none;
  white-space: nowrap;
  width: 45vw;
  padding: 2vw;
}
.container-mb #hero .img-list img {
  width: 41vw;
}

.container-mb .programe {
  padding-top: 60px;
}
.container-mb .mb-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 3em;
  text-align: center;
  color: #02315a;
}
.container-mb .programe .programe-item {
  display: flex;
  padding: 10px 32px;
  justify-content: space-between;
}
.container-mb .programe .programe-item .item-time {
  font-weight: 700;
  font-size: 20px;
  /* line-height: 2em; */
  color: #444446;
}
.container-mb .programe .programe-item .item-text {
  font-weight: 400;
  font-size: 20px;
  /* line-height: 2em; */
  color: #2a2a2a;
}
.container-mb .programe .programe-item .icon {
  width: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0e6dea;
}
.container-mb .welcoming .p {
  font-size: 17px;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 1em;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #666666;
}
.container-mb .welcoming .isfold {
  position: relative;
  height: 188px;
  width: 100%;
  overflow: hidden;
}
.container-mb .welcoming .isfold::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 24px;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.7);
}
.container-mb .welcoming .switch {
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  line-height: 1em;
  color: #02315a;
}
.container-mb .previews .previews-list {
  display: flex;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}
.container-mb .previews .previews-list .previews-item {
  width: 45vw;
  flex-shrink: 0;
  list-style: none;
  white-space: nowrap;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #dcdcdc;
}
.container-mb .previews .previews-list img {
  border-radius: 10px;
  width: 100%;
}
.container-mb .previews .previews-list h4 {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #222120;
  white-space: pre-wrap;
}
.container-mb .previews .previews-list p {
  font-size: 14px;
  white-space: pre-wrap;
  margin: 0;
}
.container-mb .testimonials {
  padding: 20px 0;
  background: none;
}
.container-mb .testimonials .testimonial-item h3 {
  color: #41708c;
}
.container-mb .testimonials .testimonial-item h4 {
  color: #333;
}
.container-mb .testimonials .testimonial-item p {
  margin: 0 auto 15px auto;
  font-style: italic;
  color: #08314f;
}

.container-mb #footer .hosted {
  text-align: center;
}
.container-mb #footer .hosted h5 {
  color: #3498db;
}
.container-mb #footer h5 {
  font-size: 15px;
  color: #2d2d2d;
}
.container-mb #footer p {
  font-size: 13px;
  color: #2d2d2d;
}
.container-mb .foot-bottom {
}
.container-mb .white {
  background: #fff;
  padding: 16px 0 25px;
}
.container-mb .white .foot-item {
  display: flex;
  margin-bottom: 20px;
}
.container-mb .white .foot-item h5,
.container-mb .white .foot-item p {
  margin-bottom: 0;
  line-height: 1.8em;
}

.container-mb .white .foot-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-size: 30px;
  line-height: 1em;
  color: #3498db;
}
.container-mb .white .foot-item .item-rt {
  flex: 1;
}
.container-mb .sign-list {
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.container-mb .sign-list img {
  width: 80%;
}
.sign-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#hero {
  width: 100%;
  position: relative;
  /* padding: 120px 0 0 0; */
  padding: 40px 0 0 0;
  /* padding-top: 20px; */
  background: url("../assets/img/slices/hero-bg.png");
  background-size: cover;
}
.container-mb #hero {
  background-image: linear-gradient(to right, #0545b5, #000c60);
}

/* #hero:before {
  content: "";
  background: rgba(2, 5, 161, 0.91);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */

#hero h1 {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  color: #222222;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  background: linear-gradient(
    0deg,
    #ffebb9 0%,
    #ffd867 65.9912109375%,
    #ffeaa7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#hero .yy {
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-shadow: -3px 5px 5px rgba(0, 0, 0, 0.3), -2px -3px 0 #000;
}

#hero h2 {
  color: #555555;
  margin: 20px 0 20px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    /* padding-top: 80px; */
  }
  #hero .text {
    text-align: center;
    width: 100%;
  }
  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    max-width: 50%;
  }

  #hero h1 {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  #hero .yy {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}
.CVH-mb {
  min-height: 100vh;
  background-color: #d9ecf9;
  font-size: 0;
}

.CVH-mb img {
  border: none;
  vertical-align: middle;
  outline: none;
  width: 100%;
}
.CVH-mb .container {
  margin: 0 auto;
  padding: 0 0;
  width: 100%;
}
.CVH-mb .wap-grid {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.CVH-mb .wap-grid-6 {
  width: 50%;
  float: left;
}
.CVH-mb .wap-grid-4 {
  width: 33.33333333%;
  float: left;
}
.CVH-mb .wap-grid-8 {
  width: 66.66666666%;
  float: left;
}
.CVH-mb .wap-grid-12 {
  width: 100%;
  float: left;
}
</style>
